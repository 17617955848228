import React from 'react'
import Layout from '../components/layout'
import { graphql, StaticQuery } from 'gatsby'
import Footer from '../components/footer'

export default () => (
	<StaticQuery
		query={graphql`
			{
				allWordpressPage(filter: { title: { eq: "Session Info" } }) {
					edges {
						node {
							acf {
								session {
									session_title
									session_information {
										paragraph_title
										session_paragraph
									}
								}
							}
						}
					}
				}
			}
		`}
		render={(props) => (
			<Layout>
				<div className='page-body'>
					<div className='column is-8-widescreen is-offset-2-widescreen text-center'>
						<h1>Allison Liffman Photography "STYLE GUIDE"</h1>
						<h2>
							<a
								href='https://wp.allisonliffmanphotography.com/wp-content/uploads/2024/04/STYLE-GUIDE-Allison-Liffman-Photography.pdf'
								download>
								Download PDF here
							</a>
						</h2>
					</div>
					<div className='column is-8-widescreen is-offset-2-widescreen'>
						{props.allWordpressPage.edges[0].node.acf.session.map((item) => (
							<div className='card'>
								<h1>{item.session_title}</h1>
								{item.session_information.map((intro) => (
									<p>
										<strong>{intro.paragraph_title} </strong>-{' '}
										{intro.session_paragraph}
									</p>
								))}
							</div>
						))}
					</div>
				</div>
				<Footer />
			</Layout>
		)}
	/>
)
